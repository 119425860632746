import React, { useState } from "react";
import axios from "axios";
import {
	Container,
	Button,
	Grid,
	Paper,
} from "@mui/material";
import {  useNavigate } from "react-router-dom";
import './CreateSites.css'

const CreateSites = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [data, setData] = useState({
   site: "",
  })

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
    
 }
  const handleSubmit=(e)=>{
    e.preventDefault();

   const sendData={
    site:data.site,
    }
    console.log(sendData)
    axios.post("https://myworxit.co.za/api/auditsdc/createSites.php",sendData)
    .then((res)=> {
      setMessage(res.data.message);
      setData({site:""})
    })
    .catch((err) => {
      console.error(err);
      setMessage("An error occurred.");
    });
  }

  return (
    <div className="delivery-form" >
		<Container maxWidth="sm" >
		<Grid
		container
		spacing={2}
		direction="column"
		justifyContent="center"
		style={{ minHeight: "60vh", }}
	>
	<Paper elelvation={2} sx={{ padding: 5 }}>
	<form  onSubmit={handleSubmit}>
	<Grid container direction="column" spacing={2}>
  <h4 className="regTitle">Create Deliveries Site</h4>
		<Grid item>
			<input
				type="text"
        name="site"
				placeholder="Site"
				variant="outlined"
        required
        value={data.site}
        onChange={handleChange}
			/>
		</Grid>
   
    {/* <Grid item>
      <select value={data.role} onChange={(e) => setData({ ...data, role: e.target.value })}>
        <option value="Dispatcher">Dispatcher</option>
        <option value="Driver">Driver</option>
      </select>
    </Grid> */}
		<Grid item>
        <Button type="submit" fullWidth variant="contained">
			Create Site
		</Button>
    {message && <p className="errorMsg">{message}</p>}
		</Grid>
	</Grid>
	</form>
	</Paper>
	</Grid>
	
	</Container>
			</div> 
  );
};

export default CreateSites;
