import React, {useState, useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import "./ViewSites.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import {
	Container,
	Grid,
	Paper,
} from "@mui/material";


const ViewSites = () =>{

    const [viewSites, setViewSites]= useState([]);
    const [loading, setLoading] = useState(false);
    const [siteValue, setSiteValue] = useState("");
    const [message, setMessage] = useState("");
      
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const usersPerPage = rowsPerPage;
    const [pageNumber, setPageNumber] = useState(0);

    const handleRowsPerPageChange = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPageNumber(0); // Reset the page number to 0
    };

    const companyTable = async () => {
      const response = await axios.get('https://myworxit.co.za/api/auditsdc/viewSites.php');
      const newData = response.data.records;
      setViewSites(newData);
    };
    useEffect(() => {
      
      companyTable();
      const intervalId = setInterval(companyTable, 3000);
      return () => clearInterval(intervalId);
    }, []);
    
    const filteredCompany= viewSites.filter(data => {

        if (siteValue !== "" ) {
          if (
            data.site.toLowerCase().includes(siteValue)
          ) {
            return data;
          }
          return false;
        }
        return data;
        });
      
      useEffect(() => {
        if (filteredCompany.length === 0 && (siteValue !== "")) {
          setMessage("No Match Found");
        } else {
          setMessage("");
        }
      }, [filteredCompany, siteValue]);

      const deleteSite = (id) =>{
        axios.delete("https://myworxit.co.za/api/auditsdc/deleteSite.php", {data: {id: id}})
        .then(()=>{
          companyTable();
        }).catch(()=>{
            alert("Error, Cannot Delete User!");
        })
    }

    return(
        
        <div className="deliveriesSiteDetails">
            <Container maxWidth="sm" >
        <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        style={{ minHeight: "30vh", }}
      >
      <Paper elelvation={2} sx={{ padding: 5 }}>
      <Grid container direction="column" spacing={2}>
        <Grid>
        <div className=" row filterSite" >
            <div className="column1Site">
                <h5>Site:</h5>           
            </div>
            <div className="column2Site"> 
                <input
                className="searchInput"
                type="text"
                placeholder= "Search..."
                onChange={(e) => setSiteValue(e.target.value)}/>  
            </div>                
    </div>
        {/* <h7>Latest 5 Orders Completed...</h7> */}
        <table className="deliveriesSiteTbl">
            <thead>
                <tr>
                    <th>site</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                    ) : (        
                      filteredCompany
                        // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                        .map((data, index) =>(
                            <tr key={index}>                                  
                                <td>{data.site}</td> 
                                <td>
                                <Link to={`/editSite/${data.id}`}><EditIcon/>
                                </Link> 
                                <button onClick={() => deleteSite(data.id)}>< DeleteForeverIcon sx={{ color: red[900] }}/></button>
                                
                                </td>
                            </tr>
                            ))
            )}
                </tbody>
            </table>   
            <div className="rows-per-page">
              <label className="lblRowCo">Rows per page:</label>
              <select className="pageRowsCo" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(filteredCompany.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
            {message && <p className="NoMatchFoundMsg">{message}</p>}  
            </Grid>
      </Grid>
      </Paper>
      </Grid>
      
      </Container>
        </div>
        
        
    )
}

export default ViewSites;