import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import './EditUser.css'

const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    username: "",
    password:"",
    confirmPassword:"",
    role:"",
  })

  useEffect(()=>{
    showAllUsers();

// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

  const { username,
  password,
  confirmPassword,
  role,
  } = data

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }



  const handleUpdate =  async (e)=>{
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
    return;
    }
   
    await axios.post("https://myworxit.co.za/api/auditsdc/updateUser.php", data)
      .then((res) => {
        if(res.data.status ==='valid'){
          setMessage("Successfully Updated");
          navigate(-1)
      }
      })
      .catch((err) => {
        console.error(err);
        setMessage("An error occurred.");
      });

  }

  const showAllUsers = async () =>{
    const result = await axios.get("https://myworxit.co.za/api/auditsdc/editUser.php?id="+id);
    setData(result.data);
    
}


  return (
    <div className="createCompany-form" >
      <Container maxWidth="sm" >
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          style={{ minHeight: "60vh", }}
        >
          <Paper elelvation={2} sx={{ padding: 5 }}>
            <form onSubmit={handleUpdate}>
              <Grid container direction="column" spacing={2}>
                <h4 className="regTitle">Edit Users</h4>
                <Grid item>
                  <label>Usersname</label>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    variant="outlined"
                    required
                    value={username}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                <label>Password</label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    variant="outlined"
                    required
                    value={password}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                <label>Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    variant="outlined"
                    required
                    value={confirmPassword}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                <label>Role</label>
                  <input
                    type="text"
                    name="role"
                    placeholder="Role"
                    variant="outlined"
                    required
                    value={role}
                    onChange={handleChange}
                  />
                </Grid>
        
                <Grid item>
                  <Button type="submit" fullWidth variant="contained">
                    Update User
                  </Button>
                  {message && <p className="errorMsg">{message}</p>}
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

      </Container>
    </div>
  );
};

export default EditUser;
