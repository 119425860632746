import React, { useEffect, useState} from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {  useNavigate } from "react-router-dom";
import axios from 'axios';
import { auditHeaderDataAtom } from '../../components/RecoilStates/auditHeaderDataAtom';
import { auditCodeAtom } from '../../components/RecoilStates/auditCodeAtom';

import { lpaFrontlineQuestionsState } from '../../components/RecoilStates/LpaFrontlineQuestionsState';
import { lpaFrontlineDataState } from '../../components/RecoilStates/LpaFrontLineDataState';
import { lpaFrontlineDetailsState } from '../../components/RecoilStates/LpaFrontlineDetailsState';

import { lpaSecurityQuestionsState } from '../../components/RecoilStates/LpaSecurityQuestionsState';
import { lpaSecurityDataState } from '../../components/RecoilStates/LpaSecurityDataState';
import { lpaSecurityDetailsState } from '../../components/RecoilStates/LpaSecurityDetailsState';

import { lpaManagementQuestionsState } from '../RecoilStates/LpaManagementQuestionsState';
import { lpaManagementDataState } from '../RecoilStates/LpaManagementDataState';
import { lpaManagementDetailsState } from '../RecoilStates/LpaManagementDetailsState';

import { lpaCashOfficeQuestionsState } from '../../components/RecoilStates/LpaCashOfficeQuestionsState';
import { lpaCashOfficeDataState } from '../../components/RecoilStates/LpaCashOfficeDataState';
import { lpaCashOfficeDetailsState } from '../../components/RecoilStates/LpaCashOfficeDetailsState';

import '../../styles/LpaStoreAnalysisStyles.css'

const FindingsLogs = () => {

    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const auditHeaderData = useRecoilValue(auditHeaderDataAtom);
    const auditCodeValue = useRecoilValue(auditCodeAtom);
    const [isAllActionsFilled, setIsAllActionsFilled] = useState(false);
    const setAuditCodeValue = useSetRecoilState(auditCodeAtom);

    
    const navigate = useNavigate();
    
       //------------TEST --------------- 

    const lpaFrontlineData = useRecoilValue(lpaFrontlineDataState)
    const lpaFrontlineQuestions = useRecoilValue(lpaFrontlineQuestionsState);

    const lpaSecurityData = useRecoilValue(lpaSecurityDataState);
    const lpaSecurityQuestions = useRecoilValue(lpaSecurityQuestionsState);

    const lpaManagementData = useRecoilValue(lpaManagementDataState);
    const lpaManagementQuestions = useRecoilValue(lpaManagementQuestionsState);

    const lpaCashOfficeData = useRecoilValue(lpaCashOfficeDataState);
    const lpaCashOfficeQuestions = useRecoilValue(lpaCashOfficeQuestionsState);

    const resetAuditCode = useResetRecoilState(auditCodeAtom)

    const resetLpaFrontlineDetailsState = useResetRecoilState(lpaFrontlineDetailsState)
    const resetlpaFrontlineDataState = useResetRecoilState(lpaFrontlineDataState)
    
    const resetLpaSecurityDetailsState = useResetRecoilState(lpaSecurityDetailsState)
    const resetLpaSecurityDataState = useResetRecoilState(lpaSecurityDataState)

    const resetLpaManagementDetailsState = useResetRecoilState(lpaManagementDetailsState)
    const resetLpaManagementDataState = useResetRecoilState(lpaManagementDataState)
        
    const resetLpaCashOfficeDetailsState= useResetRecoilState(lpaCashOfficeDetailsState)
    const resetLpaCashOfficeDataState = useResetRecoilState(lpaCashOfficeDataState)

    
    useEffect(() => {
        // Remove persisted data from local storage when the component mounts
        localStorage.removeItem('auditCodeAtom');
        

        localStorage.removeItem('lpaCashOfficeDataState');
        localStorage.removeItem('lpaCashOfficeDetailsState');

        localStorage.removeItem('lpaFrontlineDataState');
        localStorage.removeItem('lpaFrontlineDetailsState');


        localStorage.removeItem('lpaSecurityDataState');
        localStorage.removeItem('lpaSecurityDetailsState');
      }, []);

    const combinedQuestions = {
        LPAQuestions: [
            ...lpaFrontlineQuestions.LPAQuestions,
            ...lpaSecurityQuestions.LPAQuestions,
            ...lpaManagementQuestions.LPAQuestions,
            ...lpaCashOfficeQuestions.LPAQuestions
        ],
    };

    const combinedData = [
        
        ...lpaFrontlineData.responses.map((response, index) => ({
            quesId: lpaFrontlineData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaFrontlineData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaFrontlineData.scores[index],
          })),
        ...lpaSecurityData.responses.map((response, index) => ({
            quesId: lpaSecurityData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaSecurityData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaSecurityData.scores[index],
          })),
          ...lpaManagementData.responses.map((response, index) => ({
            quesId: lpaManagementData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaManagementData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaManagementData.scores[index],
          })),
        ...lpaCashOfficeData.responses.map((response, index) => ({
            quesId: lpaCashOfficeData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaCashOfficeData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaCashOfficeData.scores[index],
          })),
      ];
      
    const filteredDataDetails = combinedData.filter(details => details.scores === 'Fail');
    console.log("filteredDataDetails: ",filteredDataDetails)
    const someLength = filteredDataDetails.length;

    const initialData = {
      creationDate: "",
      responses: "",
      actions: Array(someLength).fill(""), // Initialize with empty strings
      responsPerson: Array(someLength).fill(""),
      dueDate: Array(someLength).fill(""),
    };

    const [findingsData, setFindingsData] = useState(initialData);
   
    useEffect(() => {
      // Check if all actions are filled
      const areActionsFilled =  findingsData.actions.every(action => action.trim() !== "" || 0) && 
                                findingsData.responsPerson.every(action => action.trim() !== "" || 0) &&
                                findingsData.dueDate.every(action => action.trim() !== "" || 0);
      setIsAllActionsFilled(areActionsFilled);
    }, [findingsData.actions, findingsData.responsPerson, findingsData.dueDate]);

    console.log("Data Actions: ", findingsData.actions)
            
   

    const clearState = () => {
        resetAuditCode()     

        resetLpaCashOfficeDetailsState();
        resetLpaCashOfficeDataState();

        resetLpaFrontlineDetailsState();
        resetlpaFrontlineDataState();

        resetLpaManagementDetailsState();
        resetLpaManagementDataState();
        
        resetLpaSecurityDetailsState();
        resetLpaSecurityDataState();
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        
        const dateTime = new Date().toLocaleString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        });

        const combinedQuestions = 
        filteredDataDetails
            .map((details) => (details.question));

        const combinedResponses = 
        filteredDataDetails
            .map((details) => (details.responses ));

                    
        const auditCode = auditCodeValue.auditCode;
        const user = auditHeaderData[0]?.user;
        const longitude = auditHeaderData[0]?.longitude;
        const latitude = auditHeaderData[0]?.latitude;

        const sendData={
            auditCode: auditCode,
            creationDate: dateTime,
            questions: combinedQuestions,
            responses: combinedResponses,
            actions: findingsData.actions,
            responsPerson: findingsData.responsPerson,
            dueDate: findingsData.dueDate,
        }

        const sendAuditLogs={
            auditCode: auditCode,
            completionDate: dateTime,
            user: user,
            longitude: longitude,
            latitude: latitude,
        }
            console.log('sendData-----------', sendData)
            console.log('sendAuditLogs----------',sendAuditLogs )
            axios.post("https://myworxit.co.za/api/auditsdc/findingLogs.php",sendData)
            axios.post("https://myworxit.co.za/api/auditsdc/auditLogs.php",sendAuditLogs)

            const date = new Date().toLocaleString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              });
    
              setAuditCodeValue((prevLpaData) => ({
                ...prevLpaData,
                date,
              }));
    
              const frontlineQuestions = lpaFrontlineQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
              const securityQuestions = lpaSecurityQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
              const managementQuestions = lpaManagementQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
              const cashOfficeQuestions = lpaCashOfficeQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
          
                const promises = [
                  
                axios.post("https://myworxit.co.za/api/auditsdc/frontlineSectionData.php", {
                  auditCode: lpaFrontlineData.auditCode,
                  section: lpaFrontlineData.section,
                  quesId: frontlineQuestions,
                  responses: lpaFrontlineData.responses,
                  notes: lpaFrontlineData.notes,
                  scores: lpaFrontlineData.scores,
                  totalScore: lpaFrontlineData.totalScore
                }),
                axios.post("https://myworxit.co.za/api/auditsdc/securitySectionData.php", {
                  auditCode: lpaSecurityData.auditCode,
                  section: lpaSecurityData.section,
                  quesId: securityQuestions,
                  responses: lpaSecurityData.responses,
                  notes: lpaSecurityData.notes,
                  scores: lpaSecurityData.scores,
                  totalScore: lpaSecurityData.totalScore
                }),
                axios.post("https://myworxit.co.za/api/auditsdc/managementSectionData.php", {
                  auditCode: lpaManagementData.auditCode,
                  section: lpaManagementData.section,
                  quesId: managementQuestions,
                  responses: lpaManagementData.responses,
                  notes: lpaManagementData.notes,
                  scores: lpaManagementData.scores,
                  totalScore: lpaManagementData.totalScore
                }),
                axios.post("https://myworxit.co.za/api/auditsdc/cashOfficeSectionData.php", {
                  auditCode: lpaCashOfficeData.auditCode,
                  section: lpaCashOfficeData.section,
                  quesId: cashOfficeQuestions,
                  responses: lpaCashOfficeData.responses,
                  notes: lpaCashOfficeData.notes,
                  scores: lpaCashOfficeData.scores,
                  totalScore: lpaCashOfficeData.totalScore
                })
                ];
                Promise.all(promises)
            .then((res)=> {
                //setMessage(res.data.message);   
                setErrorMessage("");
                setFindingsData({responses:"",actions:"",responsPerson:"",dueDate:""})   
                navigate('/storeAudit')
                clearState();
            })
            .catch((err) => {
                console.error(err);
                setErrorMessage("An error occurred.");
                setMessage("");
            });
    }
   
    return (
      <div className="findingLogsContainer">
           <div className='titleHeader'>
                  <h4 className='titleh4'>Alert</h4>
              </div>
         <table className='lpaStoreAnalysisTbl'>
              <thead className='lpaStoreAnalysisHeader'>
                  <tr className='lpaStoreAnalysisHeaderRow'>
                      <th className="typeColWidth">Type</th>
                      <th className="responsesColWidth">Responses</th>
                      <th className="actionColWidth">Action</th>
                      <th className="responPersColWidth">Responsible Person</th>
                      <th className="dueDateColWidth">Due Date</th>
                  </tr>
              </thead>
              <tbody className='lpaStoreAnalysisBody'>
              {filteredDataDetails
                  .map((details, index) => (
                  <tr className='lpaStoreAnalysisBodyRow' key={index}>
                      <td className="typeColWidth">
                      <div className='failCircle'></div>
                      </td>
                      <td className="responsesColWidth">
                      <div>{details.question} - {details.responses}</div>
                      </td>
                      <td className="actionColWidth">
                          <input
                              type= "text"
                              name="actions"
                              variant="outlined"
                              required
                              value={findingsData.actions[index]}
                              onChange={(e) => {
                                  const newActions = [...findingsData.actions]; // Create a copy of the actions array
                                  newActions[index] = e.target.value; // Update the value at the current index
                                  setFindingsData({ ...findingsData, actions: newActions }); // Update the state with the new array
                              }}
              />
                      </td>
                      <td className="responPersColWidth">
                          <input
                              type= "text"
                              name="responsPerson"
                              variant="outlined"
                              required
                              value={findingsData.responsPerson[index]}
                              onChange={(e) => {
                                  const newResponsPerson = [...findingsData.responsPerson]; // Create a copy of the actions array
                                  newResponsPerson[index] = e.target.value; // Update the value at the current index
                                  setFindingsData({ ...findingsData, responsPerson: newResponsPerson }); // Update the state with the new array
                              }}					
                          />
                      </td>
                      <td className="dueDateColWidth">
                      <input
                              type= "date"
                              name="dueDate"
                              variant="outlined"
                              required
                              value={findingsData.dueDate[index]}
                              onChange={(e) => {
                                  const newDueDates = [...findingsData.dueDate]; // Create a copy of the dueDate array
                                  newDueDates[index] = e.target.value; // Update the value at the current index
                                  setFindingsData({ ...findingsData, dueDate: newDueDates }); // Update the state with the new array
                              }}				
                          />
                      </td>
                  </tr>
              ))}
              </tbody>
          </table>
          <div className='submitAuditContainer'>
            <button className={`auditBtn ${!isAllActionsFilled ? 'disabled' : ''}`} onClick={handleSubmit} disabled={!isAllActionsFilled}>Submit Audit for Completion</button>
          </div>
      </div>
    );
  };
  
  export default FindingsLogs;